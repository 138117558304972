.homepage {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.homepage .div {
  background-color: #ffffff;
  border: 1px none;
  height: 7880px;
  overflow: hidden;
  position: relative;
  width: 1920px;
}

.homepage .footer {
  background-color: transparent;
  height: 492px;
  left: 4px;
  position: absolute;
  top: 7377px;
  width: 1920px;
}

.homepage .copyright {
  height: 47px;
  left: 0;
  position: absolute;
  top: 445px;
  width: 1922px;
}

.homepage .line {
  height: 1px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: -1px;
  width: 1916px;
}

.homepage .copyright-reco-co {
  color: #515b5f;
  font-family: "Open Sans", Helvetica;
  font-size: 18px;
  font-weight: 400;
  left: 631px;
  letter-spacing: 0;
  line-height: 29.8px;
  position: absolute;
  text-align: center;
  top: 17px;
  white-space: nowrap;
}

.homepage .text-wrapper {
  color: #515b5f;
  font-family: "Open Sans", Helvetica;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 29.8px;
}

.homepage .span {
  font-weight: 700;
}

.homepage .utility-pages {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 24px;
  left: 1323px;
  position: absolute;
  top: 0;
  width: 285px;
}

.homepage .text-wrapper-2 {
  color: #274c5b;
  font-family: "Roboto", Helvetica;
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.homepage .style-guide-not {
  color: #515b5f;
  font-family: "Open Sans", Helvetica;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 49.1px;
  position: relative;
  width: 285px;
}

.homepage .img {
  height: 336px;
  left: 1272px;
  object-fit: cover;
  position: absolute;
  top: 2px;
  width: 1px;
}

.homepage .about {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 23px;
  left: 688px;
  position: absolute;
  top: 0;
  width: 543px;
}

.homepage .logo {
  height: 60px;
  position: relative;
  width: 239.6px;
}

.homepage .logo-2 {
  height: 60px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 63px;
}

.homepage .logo-3 {
  height: 45px;
  left: 97px;
  position: absolute;
  top: 8px;
  width: 143px;
}

.homepage .content {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 49px;
  position: relative;
  width: 543px;
}

.homepage .connecter-les {
  color: #515b5f;
  font-family: "Open Sans", Helvetica;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 29.8px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  width: 543px;
}

.homepage .follow {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  gap: 15px;
  justify-content: center;
  position: relative;
  width: 285px;
}

.homepage .element {
  background-color: #eff6f1;
  border-radius: 30px;
  height: 60px;
  position: relative;
  width: 60px;
}

.homepage .insta {
  height: 19px;
  left: 20px;
  position: absolute;
  top: 21px;
  width: 19px;
}

.homepage .fb {
  height: 18px;
  left: 20px;
  position: absolute;
  top: 21px;
  width: 19px;
}

.homepage .twiter {
  height: 17px;
  left: 20px;
  position: absolute;
  top: 22px;
  width: 21px;
}

.homepage .pintrest {
  height: 20px;
  left: 20px;
  position: absolute;
  top: 20px;
  width: 20px;
}

.homepage .line-2 {
  height: 336px;
  left: 646px;
  object-fit: cover;
  position: absolute;
  top: 7px;
  width: 1px;
}

.homepage .contact-us {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  gap: 32px;
  left: 311px;
  position: absolute;
  top: 0;
  width: 285px;
}

.homepage .email-help-reco-co {
  color: #515b5f;
  font-family: "Open Sans", Helvetica;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 29.7px;
  position: relative;
  text-align: right;
  width: 285px;
}

.homepage .text-wrapper-3 {
  color: #515b5f;
  font-family: "Open Sans", Helvetica;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 29.7px;
}

.homepage .overlap {
  height: 1482px;
  left: -1px;
  position: absolute;
  top: 5866px;
  width: 1920px;
}

.homepage .offer {
  background-color: #274c5b;
  height: 1482px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1920px;
}

.homepage .newslatteer {
  background-image: url(../../../static/img/image-7.png);
  background-size: 100% 100%;
  height: 323px;
  left: 302px;
  position: relative;
  top: 1039px;
  width: 1400px;
}

.homepage .content-2 {
  align-items: center;
  display: flex;
  gap: 326px;
  left: 71px;
  position: relative;
  top: 105px;
  width: 1258px;
}

.homepage .heading {
  height: 113px;
  position: relative;
  width: 359px;
}

.homepage .subscribe-to-our {
  color: #ffffff;
  font-family: "Roboto", Helvetica;
  font-size: 50px;
  font-weight: 800;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 0;
  width: 357px;
}

.homepage .button {
  align-items: center;
  display: flex;
  gap: 6px;
  justify-content: flex-end;
  position: relative;
  width: 575px;
}

.homepage .mail {
  height: 80px;
  position: relative;
  width: 351px;
}

.homepage .overlap-group {
  background-color: #ffffff;
  border-radius: 16px;
  height: 80px;
  position: relative;
  width: 349px;
}

.homepage .text-wrapper-4 {
  color: #ababab;
  font-family: "Roboto", Helvetica;
  font-size: 18px;
  font-style: italic;
  font-weight: 400;
  left: 23px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 28px;
  white-space: nowrap;
}

.homepage .read-more-wrapper {
  align-items: center;
  background-color: #274c5b;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  padding: 28px 47px 29px;
  position: relative;
  width: 220px;
}

.homepage .read-more {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 9px;
  justify-content: center;
  position: relative;
}

.homepage .text-wrapper-5 {
  color: #ffffff;
  font-family: "Roboto", Helvetica;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.homepage .email-form {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 60px;
  left: 386px;
  position: absolute;
  top: 156px;
  width: 1184px;
}

.homepage .form {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 50px;
  position: relative;
  width: 1184px;
}

.homepage .element-2 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  gap: 46px;
  position: relative;
  width: 1184px;
}

.homepage .company {
  height: 127px;
  position: relative;
  width: 573px;
}

.homepage .div-wrapper {
  background-color: #ffffff;
  border: 1px solid;
  border-color: #7db593;
  border-radius: 16px;
  height: 80px;
  left: 0;
  position: absolute;
  top: 47px;
  width: 569px;
}

.homepage .text-wrapper-6 {
  color: #ababab;
  font-family: "Roboto", Helvetica;
  font-size: 18px;
  font-style: italic;
  font-weight: 400;
  left: 22px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 27px;
  white-space: nowrap;
}

.homepage .text-wrapper-7 {
  color: #ffffff;
  font-family: "Roboto", Helvetica;
  font-size: 18px;
  font-weight: 600;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.homepage .div-2 {
  height: 127px;
  margin-right: -4px;
  position: relative;
  width: 573px;
}

.homepage .element-3 {
  height: 294px;
  margin-right: -4px;
  position: relative;
  width: 1188px;
}

.homepage .p-wrapper {
  background-color: #ffffff;
  border: 1px solid;
  border-color: #7db593;
  border-radius: 16px;
  height: 247px;
  left: 0;
  position: absolute;
  top: 47px;
  width: 1184px;
}

.homepage .text-wrapper-8 {
  color: #274c5b;
  font-family: "Roboto", Helvetica;
  font-size: 18px;
  font-weight: 600;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.homepage .full-name {
  height: 127px;
  position: relative;
  width: 571px;
}

.homepage .overlap-group-wrapper {
  height: 80px;
  left: 0;
  position: absolute;
  top: 47px;
  width: 571px;
}

.homepage .overlap-group-2 {
  background-color: #ffffff;
  border: 1px solid;
  border-color: #7db593;
  border-radius: 16px;
  height: 80px;
  position: relative;
  width: 569px;
}

.homepage .element-4 {
  align-items: center;
  background-color: #ffffff;
  border: 1px solid;
  border-color: #274c5b;
  border-radius: 16px;
  display: inline-flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  left: 386px;
  padding: 28px 39px 29px;
  position: absolute;
  top: 860px;
}

.homepage .text {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  justify-content: center;
  position: relative;
}

.homepage .text-wrapper-9 {
  color: #274c5b;
  font-family: "Roboto", Helvetica;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.homepage .aerrow {
  background-color: #335b6b;
  border-radius: 9.5px;
  height: 19px;
  position: relative;
  width: 19px;
}

.homepage .vector {
  height: 7px;
  left: 5px;
  position: absolute;
  top: 6px;
  width: 8px;
}

.homepage .overlap-2 {
  height: 3053px;
  left: 0;
  position: absolute;
  top: 1256px;
  width: 2093px;
}

.homepage .overlap-wrapper {
  height: 1184px;
  left: 0;
  position: absolute;
  top: 440px;
  width: 2092px;
}

.homepage .overlap-3 {
  height: 1184px;
  position: relative;
  width: 1920px;
}

.homepage .background {
  height: 1067px;
  left: 0;
  position: absolute;
  top: 117px;
  width: 1920px;
}

.homepage .image {
  background-image: url(../../../static/img/image-6.png);
  background-size: 100% 100%;
  height: 1067px;
  left: 0;
  position: absolute;
  top: 117px;
  width: 1920px;
}

.homepage .gallery {
  align-items: center;
  background-color: #f1f7f3;
  display: inline-flex;
  flex-direction: column;
  gap: 10px;
  left: 0;
  padding: 187px 0px;
  position: absolute;
  top: 172px;
}

.homepage .gallery-2 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  gap: 42px;
  justify-content: center;
  position: relative;
  width: 1920px;
}

.homepage .element-5 {
  background-image: url(../../../static/img/image-5.png);
  background-size: 100% 100%;
  height: 583px;
  position: relative;
  width: 612px;
}

.homepage .text-2 {
  align-items: center;
  background-color: #ffffff;
  border-radius: 20px;
  display: flex;
  gap: 10px;
  height: 500px;
  justify-content: center;
  left: 56px;
  padding: 33px 83px 34px;
  position: relative;
  top: 41px;
  width: 500px;
}

.homepage .p {
  color: transparent;
  font-family: "Roboto", Helvetica;
  font-size: 30px;
  font-weight: 400;
  height: 395px;
  letter-spacing: 0;
  line-height: normal;
  margin-left: -19.5px;
  margin-right: -19.5px;
  position: relative;
  width: 373px;
}

.homepage .text-wrapper-10 {
  color: #7db593;
  font-weight: 800;
}

.homepage .text-wrapper-11 {
  color: #274c5b;
  font-size: 25px;
  font-weight: 500;
}

.homepage .element-6 {
  background-image: url(../../../static/img/image-4.png);
  background-size: 100% 100%;
  height: 583px;
  position: relative;
  width: 612px;
}

.homepage .avantages-pour-les {
  color: transparent;
  font-family: "Roboto", Helvetica;
  font-size: 30px;
  font-weight: 400;
  height: 395px;
  letter-spacing: 0;
  line-height: normal;
  margin-left: -30.5px;
  margin-right: -30.5px;
  position: relative;
  width: 395px;
}

.homepage .element-7 {
  background-image: url(../../../static/img/image-3.png);
  background-size: 100% 100%;
  height: 583px;
  position: relative;
  width: 612px;
}

.homepage .group {
  height: 273px;
  left: 1715px;
  position: absolute;
  top: 0;
  width: 205px;
}

.homepage .why-choos-us {
  height: 1478px;
  left: 1px;
  position: absolute;
  top: 1575px;
  width: 2092px;
}

.homepage .overlap-4 {
  height: 1478px;
  position: relative;
  width: 1919px;
}

.homepage .background-2 {
  height: 1429px;
  left: 0;
  position: absolute;
  top: 49px;
  width: 1919px;
}

.homepage .content-3 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 91px;
  left: 260px;
  position: absolute;
  top: 154px;
  width: 1400px;
}

.homepage .element-8 {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  gap: 51px;
  position: relative;
  width: 1400px;
}

.homepage .content-4 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 36px;
  position: relative;
  width: 671px;
}

.homepage .heading-2 {
  height: 337px;
  margin-right: -2px;
  position: relative;
  width: 673px;
}

.homepage .text-wrapper-12 {
  color: #515b5f;
  font-family: "Open Sans", Helvetica;
  font-size: 18px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: 29.8px;
  position: absolute;
  top: 247px;
  width: 671px;
}

.homepage .title {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 6px;
  left: 0;
  position: absolute;
  top: 0;
  width: 524px;
}

.homepage .text-wrapper-13 {
  color: #7db593;
  font-family: "Yellowtail", Helvetica;
  font-size: 36px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.homepage .text-wrapper-14 {
  color: #274c5b;
  font-family: "Roboto", Helvetica;
  font-size: 50px;
  font-weight: 800;
  letter-spacing: 0;
  line-height: normal;
  margin-right: -203px;
  position: relative;
  width: 727px;
}

.homepage .points {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24px;
  position: relative;
  width: 486px;
}

.homepage .element-9 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 12px;
  position: relative;
  width: 486px;
}

.homepage .title-wrapper {
  align-items: flex-start;
  background-color: #ebebeb;
  border-radius: 49px;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  padding: 29px 91px 29px 27px;
  position: relative;
}

.homepage .title-2 {
  align-items: center;
  display: inline-flex;
  gap: 7px;
  height: 23px;
  position: relative;
}

.homepage .icon {
  background-color: #7db593;
  border-radius: 9.5px;
  height: 19px;
  position: relative;
  width: 19px;
}

.homepage .ellipse {
  background-color: #ebebeb;
  border-radius: 5.5px;
  height: 11px;
  left: 4px;
  position: relative;
  top: 4px;
  width: 11px;
}

.homepage .text-wrapper-15 {
  color: #274c5b;
  font-family: "Roboto", Helvetica;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.homepage .content-5 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  margin-right: -18px;
  padding: 0px 0px 0px 60px;
  position: relative;
}

.homepage .text-wrapper-16 {
  color: #515b5f;
  font-family: "Open Sans", Helvetica;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 29.8px;
  margin-top: -1px;
  position: relative;
  width: 444px;
}

.homepage .image-2 {
  height: 579px;
  position: relative;
  width: 678px;
}

.homepage .element-10 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  gap: 30px;
  justify-content: center;
  position: relative;
  width: 1118px;
}

.homepage .element-11 {
  height: 335px;
  position: relative;
  width: 261px;
}

.homepage .overlap-group-3 {
  background-color: #ffffff;
  border-radius: 30px;
  height: 335px;
  position: relative;
  width: 257px;
}

.homepage .text-wrapper-17 {
  color: #515b5f;
  font-family: "Open Sans", Helvetica;
  font-size: 18px;
  font-weight: 400;
  left: 35px;
  letter-spacing: 0;
  line-height: 29.8px;
  position: absolute;
  text-align: center;
  top: 201px;
  width: 184px;
}

.homepage .text-wrapper-18 {
  color: #274c5b;
  font-family: "Roboto", Helvetica;
  font-size: 25px;
  font-weight: 800;
  left: 23px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 160px;
  white-space: nowrap;
}

.homepage .icon-wrapper {
  background-color: #f9f8f8;
  border-radius: 18px;
  height: 95px;
  left: 79px;
  position: absolute;
  top: 50px;
  width: 95px;
}

.homepage .icon-2 {
  height: 50px;
  left: 22px;
  position: absolute;
  top: 25px;
  width: 46px;
}

.homepage .text-wrapper-19 {
  color: #274c5b;
  font-family: "Roboto", Helvetica;
  font-size: 25px;
  font-weight: 800;
  left: 63px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 160px;
  white-space: nowrap;
}

.homepage .icon-3 {
  height: 48px;
  left: 25px;
  position: absolute;
  top: 25px;
  width: 45px;
}

.homepage .element-12 {
  height: 363px;
  position: relative;
  width: 261px;
}

.homepage .overlap-5 {
  height: 363px;
  position: relative;
  width: 257px;
}

.homepage .background-3 {
  background-color: #ffffff;
  border-radius: 30px;
  height: 335px;
  left: 0;
  position: absolute;
  top: 0;
  width: 257px;
}

.homepage .text-wrapper-20 {
  color: #274c5b;
  font-family: "Roboto", Helvetica;
  font-size: 25px;
  font-weight: 800;
  left: 51px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 160px;
  white-space: nowrap;
}

.homepage .icon-4 {
  height: 47px;
  left: 25px;
  position: absolute;
  top: 24px;
  width: 44px;
}

.homepage .element-13 {
  height: 335px;
  margin-right: -4px;
  position: relative;
  width: 261px;
}

.homepage .overlap-group-4 {
  height: 156px;
  left: 26px;
  position: absolute;
  top: 160px;
  width: 210px;
}

.homepage .text-wrapper-21 {
  color: #515b5f;
  font-family: "Open Sans", Helvetica;
  font-size: 18px;
  font-weight: 400;
  left: 9px;
  letter-spacing: 0;
  line-height: 29.8px;
  position: absolute;
  text-align: center;
  top: 41px;
  width: 184px;
}

.homepage .text-wrapper-22 {
  color: #274c5b;
  font-family: "Roboto", Helvetica;
  font-size: 25px;
  font-weight: 800;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 0;
}

.homepage .icon-5 {
  height: 44px;
  left: 26px;
  position: absolute;
  top: 25px;
  width: 51px;
}

.homepage .group-2 {
  height: 273px;
  left: 1715px;
  position: absolute;
  top: 49px;
  width: 204px;
}

.homepage .group-3 {
  height: 101px;
  left: 1640px;
  position: absolute;
  top: 0;
  width: 149px;
}

.homepage .group-4 {
  height: 383px;
  left: 599px;
  position: absolute;
  top: 349px;
  width: 368px;
}

.homepage .offer-banner {
  align-items: center;
  display: flex;
  gap: 36px;
  left: 260px;
  position: absolute;
  top: 0;
  width: 1400px;
}

.homepage .element-14 {
  background-image: url(../../../static/img/image-1.png);
  background-size: 100% 100%;
  height: 367px;
  position: relative;
  width: 682.24px;
}

.homepage .text-3 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 5px;
  left: 17px;
  position: relative;
  top: 71px;
  width: 278px;
}

.homepage .text-wrapper-23 {
  color: #ffffff;
  font-family: "Yellowtail", Helvetica;
  font-size: 36px;
  font-weight: 400;
  height: 49px;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: 131px;
}

.homepage .engagement-envers-le {
  color: #ffffff;
  font-family: "Roboto", Helvetica;
  font-size: 30px;
  font-weight: 800;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: 277.63px;
}

.homepage .element-15 {
  background-image: url(../../../static/img/image.png);
  background-size: 100% 100%;
  height: 367px;
  position: relative;
  width: 682.24px;
}

.homepage .text-4 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 5px;
  left: 15px;
  position: relative;
  top: 64px;
  width: 278px;
}

.homepage .text-wrapper-24 {
  color: #7db593;
  font-family: "Yellowtail", Helvetica;
  font-size: 36px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: 197px;
}

.homepage .text-wrapper-25 {
  color: #274c5b;
  font-family: "Roboto", Helvetica;
  font-size: 30px;
  font-weight: 800;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: 277.63px;
}

.homepage .group-5 {
  height: 196px;
  left: 0;
  position: absolute;
  top: 459px;
  width: 128px;
}

.homepage .banner {
  height: 898px;
  left: 0;
  position: absolute;
  top: 204px;
  width: 1920px;
}

.homepage .header {
  align-items: center;
  background-color: #ffffffe6;
  display: inline-flex;
  flex-direction: column;
  gap: 10px;
  left: 0;
  padding: 69px 162px;
  position: absolute;
  top: 0;
}

.homepage .header-2 {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
  width: 1595.79px;
}

.homepage .logo-4 {
  height: 60px;
  object-fit: cover;
  position: relative;
  width: 63.19px;
}

.homepage .element-16 {
  align-items: center;
  display: flex;
  gap: 150px;
  position: relative;
  width: 912.79px;
}

.homepage .logo-5 {
  height: 45px;
  position: relative;
  width: 143px;
}

.homepage .menu {
  height: 23px;
  position: relative;
  width: 566px;
}

.homepage .overlap-group-5 {
  height: 23px;
  position: relative;
  width: 564px;
}

.homepage .home-about-pages {
  color: #274c5b;
  font-family: "Roboto", Helvetica;
  font-size: 20px;
  font-weight: 700;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.homepage .icon-6 {
  height: 4px;
  left: 257px;
  position: absolute;
  top: 10px;
  width: 8px;
}

.homepage .element-17 {
  align-items: center;
  display: flex;
  gap: 18px;
  justify-content: flex-end;
  position: relative;
  width: 553px;
}

.homepage .search {
  background-color: #f9f9f9;
  border-radius: 36px;
  height: 66px;
  position: relative;
  width: 376px;
}

.homepage .search-icon {
  background-color: #7db593;
  border-radius: 28px;
  height: 56px;
  left: 314px;
  position: relative;
  top: 5px;
  width: 56px;
}

.homepage .vector-2 {
  height: 21px;
  left: 19px;
  position: absolute;
  top: 17px;
  width: 21px;
}

.homepage .cart {
  height: 66px;
  margin-right: -2px;
  position: relative;
  width: 161px;
}

.homepage .overlap-group-6 {
  border: 1px solid;
  border-color: #e0e0e0;
  border-radius: 33px;
  height: 66px;
  position: relative;
  width: 159px;
}

.homepage .text-wrapper-26 {
  color: #274c5b;
  font-family: "Roboto", Helvetica;
  font-size: 18px;
  font-weight: 600;
  left: 73px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 20px;
  white-space: nowrap;
}

.homepage .cart-icon {
  background-color: #274c5b;
  border-radius: 28px;
  height: 56px;
  left: 5px;
  position: absolute;
  top: 4px;
  width: 56px;
}

.homepage .icon-7 {
  height: 24px;
  left: 14px;
  position: absolute;
  top: 16px;
  width: 26px;
}

.homepage .logo-6 {
  height: 45px;
  left: 811px;
  position: absolute;
  top: 1148px;
  width: 298px;
}

.homepage .shop {
  height: 1286px;
  left: 269px;
  position: absolute;
  top: 4445px;
  width: 1400px;
}
