.homepage {
  background-color: #fff;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  display: flex;
}

.homepage .div {
  background-color: #fff;
  border: 1px;
  width: 1920px;
  height: 7880px;
  position: relative;
  overflow: hidden;
}

.homepage .footer {
  background-color: #0000;
  width: 1920px;
  height: 492px;
  position: absolute;
  top: 7377px;
  left: 4px;
}

.homepage .copyright {
  width: 1922px;
  height: 47px;
  position: absolute;
  top: 445px;
  left: 0;
}

.homepage .line {
  object-fit: cover;
  width: 1916px;
  height: 1px;
  position: absolute;
  top: -1px;
  left: 0;
}

.homepage .copyright-reco-co {
  color: #515b5f;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  font-family: Open Sans, Helvetica;
  font-size: 18px;
  font-weight: 400;
  line-height: 29.8px;
  position: absolute;
  top: 17px;
  left: 631px;
}

.homepage .text-wrapper {
  color: #515b5f;
  letter-spacing: 0;
  font-family: Open Sans, Helvetica;
  font-size: 18px;
  font-weight: 400;
  line-height: 29.8px;
}

.homepage .span {
  font-weight: 700;
}

.homepage .utility-pages {
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  width: 285px;
  display: flex;
  position: absolute;
  top: 0;
  left: 1323px;
}

.homepage .text-wrapper-2 {
  color: #274c5b;
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Roboto, Helvetica;
  font-size: 30px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.homepage .style-guide-not {
  color: #515b5f;
  letter-spacing: 0;
  width: 285px;
  font-family: Open Sans, Helvetica;
  font-size: 18px;
  font-weight: 400;
  line-height: 49.1px;
  position: relative;
}

.homepage .img {
  object-fit: cover;
  width: 1px;
  height: 336px;
  position: absolute;
  top: 2px;
  left: 1272px;
}

.homepage .about {
  flex-direction: column;
  align-items: center;
  gap: 23px;
  width: 543px;
  display: flex;
  position: absolute;
  top: 0;
  left: 688px;
}

.homepage .logo {
  width: 239.6px;
  height: 60px;
  position: relative;
}

.homepage .logo-2 {
  object-fit: cover;
  width: 63px;
  height: 60px;
  position: absolute;
  top: 0;
  left: 0;
}

.homepage .logo-3 {
  width: 143px;
  height: 45px;
  position: absolute;
  top: 8px;
  left: 97px;
}

.homepage .content {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 49px;
  width: 543px;
  display: flex;
  position: relative;
}

.homepage .connecter-les {
  color: #515b5f;
  letter-spacing: 0;
  text-align: center;
  width: 543px;
  margin-top: -1px;
  font-family: Open Sans, Helvetica;
  font-size: 18px;
  font-weight: 700;
  line-height: 29.8px;
  position: relative;
}

.homepage .follow {
  flex: none;
  justify-content: center;
  align-items: flex-start;
  gap: 15px;
  width: 285px;
  display: flex;
  position: relative;
}

.homepage .element {
  background-color: #eff6f1;
  border-radius: 30px;
  width: 60px;
  height: 60px;
  position: relative;
}

.homepage .insta {
  width: 19px;
  height: 19px;
  position: absolute;
  top: 21px;
  left: 20px;
}

.homepage .fb {
  width: 19px;
  height: 18px;
  position: absolute;
  top: 21px;
  left: 20px;
}

.homepage .twiter {
  width: 21px;
  height: 17px;
  position: absolute;
  top: 22px;
  left: 20px;
}

.homepage .pintrest {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 20px;
  left: 20px;
}

.homepage .line-2 {
  object-fit: cover;
  width: 1px;
  height: 336px;
  position: absolute;
  top: 7px;
  left: 646px;
}

.homepage .contact-us {
  flex-direction: column;
  align-items: flex-end;
  gap: 32px;
  width: 285px;
  display: flex;
  position: absolute;
  top: 0;
  left: 311px;
}

.homepage .email-help-reco-co {
  color: #515b5f;
  letter-spacing: 0;
  text-align: right;
  width: 285px;
  font-family: Open Sans, Helvetica;
  font-size: 18px;
  font-weight: 400;
  line-height: 29.7px;
  position: relative;
}

.homepage .text-wrapper-3 {
  color: #515b5f;
  letter-spacing: 0;
  font-family: Open Sans, Helvetica;
  font-size: 18px;
  font-weight: 400;
  line-height: 29.7px;
}

.homepage .overlap {
  width: 1920px;
  height: 1482px;
  position: absolute;
  top: 5866px;
  left: -1px;
}

.homepage .offer {
  background-color: #274c5b;
  width: 1920px;
  height: 1482px;
  position: absolute;
  top: 0;
  left: 0;
}

.homepage .newslatteer {
  background-image: url("image-7.e252b71a.png");
  background-size: 100% 100%;
  width: 1400px;
  height: 323px;
  position: relative;
  top: 1039px;
  left: 302px;
}

.homepage .content-2 {
  align-items: center;
  gap: 326px;
  width: 1258px;
  display: flex;
  position: relative;
  top: 105px;
  left: 71px;
}

.homepage .heading {
  width: 359px;
  height: 113px;
  position: relative;
}

.homepage .subscribe-to-our {
  color: #fff;
  letter-spacing: 0;
  width: 357px;
  font-family: Roboto, Helvetica;
  font-size: 50px;
  font-weight: 800;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.homepage .button {
  justify-content: flex-end;
  align-items: center;
  gap: 6px;
  width: 575px;
  display: flex;
  position: relative;
}

.homepage .mail {
  width: 351px;
  height: 80px;
  position: relative;
}

.homepage .overlap-group {
  background-color: #fff;
  border-radius: 16px;
  width: 349px;
  height: 80px;
  position: relative;
}

.homepage .text-wrapper-4 {
  color: #ababab;
  letter-spacing: 0;
  white-space: nowrap;
  font-family: Roboto, Helvetica;
  font-size: 18px;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 28px;
  left: 23px;
}

.homepage .read-more-wrapper {
  background-color: #274c5b;
  border-radius: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 220px;
  padding: 28px 47px 29px;
  display: flex;
  position: relative;
}

.homepage .read-more {
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 9px;
  display: inline-flex;
  position: relative;
}

.homepage .text-wrapper-5 {
  color: #fff;
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Roboto, Helvetica;
  font-size: 20px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.homepage .email-form {
  flex-direction: column;
  align-items: flex-start;
  gap: 60px;
  width: 1184px;
  display: flex;
  position: absolute;
  top: 156px;
  left: 386px;
}

.homepage .form {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 50px;
  width: 1184px;
  display: flex;
  position: relative;
}

.homepage .element-2 {
  flex: none;
  align-items: flex-start;
  gap: 46px;
  width: 1184px;
  display: flex;
  position: relative;
}

.homepage .company {
  width: 573px;
  height: 127px;
  position: relative;
}

.homepage .div-wrapper {
  background-color: #fff;
  border: 1px solid #7db593;
  border-radius: 16px;
  width: 569px;
  height: 80px;
  position: absolute;
  top: 47px;
  left: 0;
}

.homepage .text-wrapper-6 {
  color: #ababab;
  letter-spacing: 0;
  white-space: nowrap;
  font-family: Roboto, Helvetica;
  font-size: 18px;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 27px;
  left: 22px;
}

.homepage .text-wrapper-7 {
  color: #fff;
  letter-spacing: 0;
  white-space: nowrap;
  font-family: Roboto, Helvetica;
  font-size: 18px;
  font-weight: 600;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.homepage .div-2 {
  width: 573px;
  height: 127px;
  margin-right: -4px;
  position: relative;
}

.homepage .element-3 {
  width: 1188px;
  height: 294px;
  margin-right: -4px;
  position: relative;
}

.homepage .p-wrapper {
  background-color: #fff;
  border: 1px solid #7db593;
  border-radius: 16px;
  width: 1184px;
  height: 247px;
  position: absolute;
  top: 47px;
  left: 0;
}

.homepage .text-wrapper-8 {
  color: #274c5b;
  letter-spacing: 0;
  white-space: nowrap;
  font-family: Roboto, Helvetica;
  font-size: 18px;
  font-weight: 600;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.homepage .full-name {
  width: 571px;
  height: 127px;
  position: relative;
}

.homepage .overlap-group-wrapper {
  width: 571px;
  height: 80px;
  position: absolute;
  top: 47px;
  left: 0;
}

.homepage .overlap-group-2 {
  background-color: #fff;
  border: 1px solid #7db593;
  border-radius: 16px;
  width: 569px;
  height: 80px;
  position: relative;
}

.homepage .element-4 {
  background-color: #fff;
  border: 1px solid #274c5b;
  border-radius: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 28px 39px 29px;
  display: inline-flex;
  position: absolute;
  top: 860px;
  left: 386px;
}

.homepage .text {
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 10px;
  display: inline-flex;
  position: relative;
}

.homepage .text-wrapper-9 {
  color: #274c5b;
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Roboto, Helvetica;
  font-size: 20px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.homepage .aerrow {
  background-color: #335b6b;
  border-radius: 9.5px;
  width: 19px;
  height: 19px;
  position: relative;
}

.homepage .vector {
  width: 8px;
  height: 7px;
  position: absolute;
  top: 6px;
  left: 5px;
}

.homepage .overlap-2 {
  width: 2093px;
  height: 3053px;
  position: absolute;
  top: 1256px;
  left: 0;
}

.homepage .overlap-wrapper {
  width: 2092px;
  height: 1184px;
  position: absolute;
  top: 440px;
  left: 0;
}

.homepage .overlap-3 {
  width: 1920px;
  height: 1184px;
  position: relative;
}

.homepage .background {
  width: 1920px;
  height: 1067px;
  position: absolute;
  top: 117px;
  left: 0;
}

.homepage .image {
  background-image: url("image-6.2b50df34.png");
  background-size: 100% 100%;
  width: 1920px;
  height: 1067px;
  position: absolute;
  top: 117px;
  left: 0;
}

.homepage .gallery {
  background-color: #f1f7f3;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 187px 0;
  display: inline-flex;
  position: absolute;
  top: 172px;
  left: 0;
}

.homepage .gallery-2 {
  flex: none;
  justify-content: center;
  align-items: flex-start;
  gap: 42px;
  width: 1920px;
  display: flex;
  position: relative;
}

.homepage .element-5 {
  background-image: url("image-5.67650433.png");
  background-size: 100% 100%;
  width: 612px;
  height: 583px;
  position: relative;
}

.homepage .text-2 {
  background-color: #fff;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 500px;
  height: 500px;
  padding: 33px 83px 34px;
  display: flex;
  position: relative;
  top: 41px;
  left: 56px;
}

.homepage .p {
  color: #0000;
  letter-spacing: 0;
  width: 373px;
  height: 395px;
  margin-left: -19.5px;
  margin-right: -19.5px;
  font-family: Roboto, Helvetica;
  font-size: 30px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.homepage .text-wrapper-10 {
  color: #7db593;
  font-weight: 800;
}

.homepage .text-wrapper-11 {
  color: #274c5b;
  font-size: 25px;
  font-weight: 500;
}

.homepage .element-6 {
  background-image: url("image-4.b47b1171.png");
  background-size: 100% 100%;
  width: 612px;
  height: 583px;
  position: relative;
}

.homepage .avantages-pour-les {
  color: #0000;
  letter-spacing: 0;
  width: 395px;
  height: 395px;
  margin-left: -30.5px;
  margin-right: -30.5px;
  font-family: Roboto, Helvetica;
  font-size: 30px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.homepage .element-7 {
  background-image: url("image-3.f2a991a1.png");
  background-size: 100% 100%;
  width: 612px;
  height: 583px;
  position: relative;
}

.homepage .group {
  width: 205px;
  height: 273px;
  position: absolute;
  top: 0;
  left: 1715px;
}

.homepage .why-choos-us {
  width: 2092px;
  height: 1478px;
  position: absolute;
  top: 1575px;
  left: 1px;
}

.homepage .overlap-4 {
  width: 1919px;
  height: 1478px;
  position: relative;
}

.homepage .background-2 {
  width: 1919px;
  height: 1429px;
  position: absolute;
  top: 49px;
  left: 0;
}

.homepage .content-3 {
  flex-direction: column;
  align-items: center;
  gap: 91px;
  width: 1400px;
  display: flex;
  position: absolute;
  top: 154px;
  left: 260px;
}

.homepage .element-8 {
  flex: none;
  align-items: center;
  gap: 51px;
  width: 1400px;
  display: flex;
  position: relative;
}

.homepage .content-4 {
  flex-direction: column;
  align-items: flex-start;
  gap: 36px;
  width: 671px;
  display: flex;
  position: relative;
}

.homepage .heading-2 {
  width: 673px;
  height: 337px;
  margin-right: -2px;
  position: relative;
}

.homepage .text-wrapper-12 {
  color: #515b5f;
  letter-spacing: 0;
  width: 671px;
  font-family: Open Sans, Helvetica;
  font-size: 18px;
  font-weight: 400;
  line-height: 29.8px;
  position: absolute;
  top: 247px;
  left: 0;
}

.homepage .title {
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  width: 524px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.homepage .text-wrapper-13 {
  color: #7db593;
  letter-spacing: 0;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Yellowtail, Helvetica;
  font-size: 36px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.homepage .text-wrapper-14 {
  color: #274c5b;
  letter-spacing: 0;
  width: 727px;
  margin-right: -203px;
  font-family: Roboto, Helvetica;
  font-size: 50px;
  font-weight: 800;
  line-height: normal;
  position: relative;
}

.homepage .points {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 24px;
  width: 486px;
  display: flex;
  position: relative;
}

.homepage .element-9 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 12px;
  width: 486px;
  display: flex;
  position: relative;
}

.homepage .title-wrapper {
  background-color: #ebebeb;
  border-radius: 49px;
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  padding: 29px 91px 29px 27px;
  display: inline-flex;
  position: relative;
}

.homepage .title-2 {
  align-items: center;
  gap: 7px;
  height: 23px;
  display: inline-flex;
  position: relative;
}

.homepage .icon {
  background-color: #7db593;
  border-radius: 9.5px;
  width: 19px;
  height: 19px;
  position: relative;
}

.homepage .ellipse {
  background-color: #ebebeb;
  border-radius: 5.5px;
  width: 11px;
  height: 11px;
  position: relative;
  top: 4px;
  left: 4px;
}

.homepage .text-wrapper-15 {
  color: #274c5b;
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Roboto, Helvetica;
  font-size: 20px;
  font-weight: 500;
  line-height: normal;
  position: relative;
}

.homepage .content-5 {
  flex: none;
  align-items: flex-start;
  gap: 10px;
  margin-right: -18px;
  padding: 0 0 0 60px;
  display: inline-flex;
  position: relative;
}

.homepage .text-wrapper-16 {
  color: #515b5f;
  letter-spacing: 0;
  width: 444px;
  margin-top: -1px;
  font-family: Open Sans, Helvetica;
  font-size: 18px;
  font-weight: 400;
  line-height: 29.8px;
  position: relative;
}

.homepage .image-2 {
  width: 678px;
  height: 579px;
  position: relative;
}

.homepage .element-10 {
  flex: none;
  justify-content: center;
  align-items: flex-start;
  gap: 30px;
  width: 1118px;
  display: flex;
  position: relative;
}

.homepage .element-11 {
  width: 261px;
  height: 335px;
  position: relative;
}

.homepage .overlap-group-3 {
  background-color: #fff;
  border-radius: 30px;
  width: 257px;
  height: 335px;
  position: relative;
}

.homepage .text-wrapper-17 {
  color: #515b5f;
  letter-spacing: 0;
  text-align: center;
  width: 184px;
  font-family: Open Sans, Helvetica;
  font-size: 18px;
  font-weight: 400;
  line-height: 29.8px;
  position: absolute;
  top: 201px;
  left: 35px;
}

.homepage .text-wrapper-18 {
  color: #274c5b;
  letter-spacing: 0;
  white-space: nowrap;
  font-family: Roboto, Helvetica;
  font-size: 25px;
  font-weight: 800;
  line-height: normal;
  position: absolute;
  top: 160px;
  left: 23px;
}

.homepage .icon-wrapper {
  background-color: #f9f8f8;
  border-radius: 18px;
  width: 95px;
  height: 95px;
  position: absolute;
  top: 50px;
  left: 79px;
}

.homepage .icon-2 {
  width: 46px;
  height: 50px;
  position: absolute;
  top: 25px;
  left: 22px;
}

.homepage .text-wrapper-19 {
  color: #274c5b;
  letter-spacing: 0;
  white-space: nowrap;
  font-family: Roboto, Helvetica;
  font-size: 25px;
  font-weight: 800;
  line-height: normal;
  position: absolute;
  top: 160px;
  left: 63px;
}

.homepage .icon-3 {
  width: 45px;
  height: 48px;
  position: absolute;
  top: 25px;
  left: 25px;
}

.homepage .element-12 {
  width: 261px;
  height: 363px;
  position: relative;
}

.homepage .overlap-5 {
  width: 257px;
  height: 363px;
  position: relative;
}

.homepage .background-3 {
  background-color: #fff;
  border-radius: 30px;
  width: 257px;
  height: 335px;
  position: absolute;
  top: 0;
  left: 0;
}

.homepage .text-wrapper-20 {
  color: #274c5b;
  letter-spacing: 0;
  white-space: nowrap;
  font-family: Roboto, Helvetica;
  font-size: 25px;
  font-weight: 800;
  line-height: normal;
  position: absolute;
  top: 160px;
  left: 51px;
}

.homepage .icon-4 {
  width: 44px;
  height: 47px;
  position: absolute;
  top: 24px;
  left: 25px;
}

.homepage .element-13 {
  width: 261px;
  height: 335px;
  margin-right: -4px;
  position: relative;
}

.homepage .overlap-group-4 {
  width: 210px;
  height: 156px;
  position: absolute;
  top: 160px;
  left: 26px;
}

.homepage .text-wrapper-21 {
  color: #515b5f;
  letter-spacing: 0;
  text-align: center;
  width: 184px;
  font-family: Open Sans, Helvetica;
  font-size: 18px;
  font-weight: 400;
  line-height: 29.8px;
  position: absolute;
  top: 41px;
  left: 9px;
}

.homepage .text-wrapper-22 {
  color: #274c5b;
  letter-spacing: 0;
  font-family: Roboto, Helvetica;
  font-size: 25px;
  font-weight: 800;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.homepage .icon-5 {
  width: 51px;
  height: 44px;
  position: absolute;
  top: 25px;
  left: 26px;
}

.homepage .group-2 {
  width: 204px;
  height: 273px;
  position: absolute;
  top: 49px;
  left: 1715px;
}

.homepage .group-3 {
  width: 149px;
  height: 101px;
  position: absolute;
  top: 0;
  left: 1640px;
}

.homepage .group-4 {
  width: 368px;
  height: 383px;
  position: absolute;
  top: 349px;
  left: 599px;
}

.homepage .offer-banner {
  align-items: center;
  gap: 36px;
  width: 1400px;
  display: flex;
  position: absolute;
  top: 0;
  left: 260px;
}

.homepage .element-14 {
  background-image: url("image-1.303525eb.png");
  background-size: 100% 100%;
  width: 682.24px;
  height: 367px;
  position: relative;
}

.homepage .text-3 {
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  width: 278px;
  display: flex;
  position: relative;
  top: 71px;
  left: 17px;
}

.homepage .text-wrapper-23 {
  color: #fff;
  letter-spacing: 0;
  width: 131px;
  height: 49px;
  margin-top: -1px;
  font-family: Yellowtail, Helvetica;
  font-size: 36px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.homepage .engagement-envers-le {
  color: #fff;
  letter-spacing: 0;
  width: 277.63px;
  font-family: Roboto, Helvetica;
  font-size: 30px;
  font-weight: 800;
  line-height: normal;
  position: relative;
}

.homepage .element-15 {
  background-image: url("image.b129bffd.png");
  background-size: 100% 100%;
  width: 682.24px;
  height: 367px;
  position: relative;
}

.homepage .text-4 {
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  width: 278px;
  display: flex;
  position: relative;
  top: 64px;
  left: 15px;
}

.homepage .text-wrapper-24 {
  color: #7db593;
  letter-spacing: 0;
  width: 197px;
  margin-top: -1px;
  font-family: Yellowtail, Helvetica;
  font-size: 36px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.homepage .text-wrapper-25 {
  color: #274c5b;
  letter-spacing: 0;
  width: 277.63px;
  font-family: Roboto, Helvetica;
  font-size: 30px;
  font-weight: 800;
  line-height: normal;
  position: relative;
}

.homepage .group-5 {
  width: 128px;
  height: 196px;
  position: absolute;
  top: 459px;
  left: 0;
}

.homepage .banner {
  width: 1920px;
  height: 898px;
  position: absolute;
  top: 204px;
  left: 0;
}

.homepage .header {
  background-color: #ffffffe6;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 69px 162px;
  display: inline-flex;
  position: absolute;
  top: 0;
  left: 0;
}

.homepage .header-2 {
  flex: none;
  justify-content: space-between;
  align-items: center;
  width: 1595.79px;
  display: flex;
  position: relative;
}

.homepage .logo-4 {
  object-fit: cover;
  width: 63.19px;
  height: 60px;
  position: relative;
}

.homepage .element-16 {
  align-items: center;
  gap: 150px;
  width: 912.79px;
  display: flex;
  position: relative;
}

.homepage .logo-5 {
  width: 143px;
  height: 45px;
  position: relative;
}

.homepage .menu {
  width: 566px;
  height: 23px;
  position: relative;
}

.homepage .overlap-group-5 {
  width: 564px;
  height: 23px;
  position: relative;
}

.homepage .home-about-pages {
  color: #274c5b;
  letter-spacing: 0;
  white-space: nowrap;
  font-family: Roboto, Helvetica;
  font-size: 20px;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.homepage .icon-6 {
  width: 8px;
  height: 4px;
  position: absolute;
  top: 10px;
  left: 257px;
}

.homepage .element-17 {
  justify-content: flex-end;
  align-items: center;
  gap: 18px;
  width: 553px;
  display: flex;
  position: relative;
}

.homepage .search {
  background-color: #f9f9f9;
  border-radius: 36px;
  width: 376px;
  height: 66px;
  position: relative;
}

.homepage .search-icon {
  background-color: #7db593;
  border-radius: 28px;
  width: 56px;
  height: 56px;
  position: relative;
  top: 5px;
  left: 314px;
}

.homepage .vector-2 {
  width: 21px;
  height: 21px;
  position: absolute;
  top: 17px;
  left: 19px;
}

.homepage .cart {
  width: 161px;
  height: 66px;
  margin-right: -2px;
  position: relative;
}

.homepage .overlap-group-6 {
  border: 1px solid #e0e0e0;
  border-radius: 33px;
  width: 159px;
  height: 66px;
  position: relative;
}

.homepage .text-wrapper-26 {
  color: #274c5b;
  letter-spacing: 0;
  white-space: nowrap;
  font-family: Roboto, Helvetica;
  font-size: 18px;
  font-weight: 600;
  line-height: normal;
  position: absolute;
  top: 20px;
  left: 73px;
}

.homepage .cart-icon {
  background-color: #274c5b;
  border-radius: 28px;
  width: 56px;
  height: 56px;
  position: absolute;
  top: 4px;
  left: 5px;
}

.homepage .icon-7 {
  width: 26px;
  height: 24px;
  position: absolute;
  top: 16px;
  left: 14px;
}

.homepage .logo-6 {
  width: 298px;
  height: 45px;
  position: absolute;
  top: 1148px;
  left: 811px;
}

.homepage .shop {
  width: 1400px;
  height: 1286px;
  position: absolute;
  top: 4445px;
  left: 269px;
}

/*# sourceMappingURL=index.1fd8238b.css.map */
